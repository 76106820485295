<script setup lang="ts">
import { BOOKING_STATES, PAYMENT_MBWAY_STATUS } from '@yescapa-dev/ysc-api-js/modern'
import { STATES_UI_PAYMENT, PaymentErrorCategory } from '~/types/bookingPayment'
import { YSC_API_BOOKING_GUEST_ERROR, YSC_API_PAYMENT_ERROR, YSC_API_PAYMENT_FINALIZATION_ERROR } from '~/utils/error/YscErrorClasses'

type StateUI = {
  id: STATES_UI_PAYMENT
  label: string
  nextStateId?: STATES_UI_PAYMENT
  perform?: CallbackFnOptional
}

interface Props {
  idBooking: string | number
  transactionId?: string | number
  mbWayPaymentId?: number
  phone?: string | null
}

const props = defineProps<Props>()

const emits = defineEmits<{
  error: [{ errorCode: string }]
  success: []
  abort: []
}>()

// state machine
const stateModel = defineModel<STATES_UI_PAYMENT>('state', {
  default: STATES_UI_PAYMENT.IDLE,
})

const states = new Map<STATES_UI_PAYMENT, StateUI>()

const addState = (s: StateUI) => {
  states.set(s.id, s)
}

class ProcessingPaymentError extends Error {
  errorCode: string

  constructor(message: string, code?: string | null) {
    super(message)
    this.errorCode = code ?? PaymentErrorCategory.YSC_PAYMENTS
  }
}

const loopBreaker = ref<boolean>(false)

// initial
addState({
  id: STATES_UI_PAYMENT.IDLE,
  label: 'IDLE',
  nextStateId: STATES_UI_PAYMENT.PREPARATION,
})

// checkout info request
addState({
  id: STATES_UI_PAYMENT.PREPARATION,
  label: 'PREPARATION',
  nextStateId: STATES_UI_PAYMENT.EXECUTION,
})

// execution request
addState({
  id: STATES_UI_PAYMENT.EXECUTION,
  label: 'EXECUTION',
  nextStateId: STATES_UI_PAYMENT.FINALIZATION,
})

// finalize payment request
const { $api } = useYscApi()
const { withErrorManagerHandling } = useWithErrorManagerHandling()
addState({
  id: STATES_UI_PAYMENT.FINALIZATION,
  label: 'FINALIZATION',
  nextStateId: STATES_UI_PAYMENT.SYNCHRONISATION,
  perform: async () => {
    try {
      const {
        error_pay_in_code,
        pay_in_is_ok,
      } = await withErrorManagerHandling(
        () => $api.requests.finalizePayment(props.idBooking, { transactionId: `${props.transactionId}` }),
        YSC_API_PAYMENT_FINALIZATION_ERROR,
        true,
      )

      if (!pay_in_is_ok || (isDefined(error_pay_in_code) && error_pay_in_code !== '000000')) {
        throw new ProcessingPaymentError('FINALIZATION STATE', error_pay_in_code)
      }
    }

    catch (_e) {
      throw new ProcessingPaymentError('FINALIZATION STATE')
    }
  },
})

// booking synchronisation
const bookingguestStore = useBookingsGuestStore()
const isBookingConfirmed = computed(() => bookingguestStore.bookingGuest?.state === BOOKING_STATES.TO_COME)

addState({
  id: STATES_UI_PAYMENT.SYNCHRONISATION,
  label: 'SYNCHRONISATION',
  nextStateId: STATES_UI_PAYMENT.COMPLETED,
  perform: async () => {
    const TIME_IN_MS_BETWEEN_CHECKS = 3000
    const MAX_TIME_TO_WAIT_IN_MS = TIME_IN_MS_BETWEEN_CHECKS * 10
    let elapsedTimeInMs = 0
    while (elapsedTimeInMs < MAX_TIME_TO_WAIT_IN_MS && !isBookingConfirmed.value && !loopBreaker.value) {
      await new Promise(resolve => setTimeout(resolve, TIME_IN_MS_BETWEEN_CHECKS))
      elapsedTimeInMs += TIME_IN_MS_BETWEEN_CHECKS
      await withErrorManagerHandling(
        () => bookingguestStore.fetchBookingGuest({ id: props.idBooking, refresh: true }),
        YSC_API_BOOKING_GUEST_ERROR,
        false,
      )
    }

    if (!isBookingConfirmed.value) {
      throw new ProcessingPaymentError('SYNCHRONISATION STATE', PaymentErrorCategory.YSC_PAYMENT_CONFIMRATION_TOO_LONG)
    }
  },
})

const mbWayPaymentStatus = ref<PAYMENT_MBWAY_STATUS>()
const isMbWayProcessing = computed(() => !isDefinedRef(mbWayPaymentStatus) || mbWayPaymentStatus.value === PAYMENT_MBWAY_STATUS.CREATED)
const shouldDisplayMbWayRetry = ref<boolean>(false)

// MB Way created
addState({
  id: STATES_UI_PAYMENT.MB_WAY_EXECUTION,
  label: 'MB_WAY_EXECUTION',
})

// the only way to get updates on payment status is to fetch it until it changes see
addState({
  id: STATES_UI_PAYMENT.MB_WAY_FINALIZATION,
  label: 'MB_WAY_FINALIZATION',
  nextStateId: STATES_UI_PAYMENT.SYNCHRONISATION,
  perform: async () => {
    const mbWayPaymentId = props.mbWayPaymentId
    if (!isDefined(mbWayPaymentId)) {
      throw new ProcessingPaymentError('MB_WAY_FINALIZATION STATE')
    }

    const TIME_IN_MS_BETWEEN_CHECKS = 5000
    const MAX_TIME_TO_WAIT_IN_MS = TIME_IN_MS_BETWEEN_CHECKS * 20

    let elapsedTimeInMs = 0

    while (elapsedTimeInMs < MAX_TIME_TO_WAIT_IN_MS && isMbWayProcessing.value && !loopBreaker.value) {
      await new Promise(resolve => setTimeout(resolve, TIME_IN_MS_BETWEEN_CHECKS))
      elapsedTimeInMs += TIME_IN_MS_BETWEEN_CHECKS
      try {
        const { status } = await withErrorManagerHandling(
          () => $api.requests.getAsyncPayment(props.idBooking, mbWayPaymentId),
          YSC_API_PAYMENT_ERROR,
          true,
        )

        // TODO: fix api-js typing
        mbWayPaymentStatus.value = status as PAYMENT_MBWAY_STATUS
      }
      catch (_e) {
        throw new ProcessingPaymentError(PaymentErrorCategory.YSC_PAYMENTS)
      }
      if (elapsedTimeInMs / TIME_IN_MS_BETWEEN_CHECKS === 2) {
        shouldDisplayMbWayRetry.value = true
      }
    }

    if (mbWayPaymentStatus.value !== PAYMENT_MBWAY_STATUS.SUCCEEDED) {
      throw new ProcessingPaymentError(PaymentErrorCategory.YSC_PAYMENTS)
    }
  },
})

// final state
addState({
  id: STATES_UI_PAYMENT.COMPLETED,
  label: 'COMPLETED',
  perform: () => {
    emits('success')
  },
})

// current state logic
const currentState = computed(() => states.get(stateModel.value))

watch(currentState, async () => {
  if (isDefined(currentState.value?.perform)) {
    try {
      await currentState.value.perform()
    }
    catch (e) {
      loopBreaker.value = true

      if (e instanceof ProcessingPaymentError) {
        emits('error', { errorCode: e.errorCode })
        return
      }
      throw e
    }

    if (isDefined(currentState.value.nextStateId)) {
      stateModel.value = currentState.value.nextStateId
    }
  }
}, {
  immediate: true,
})

onUnmounted(() => {
  loopBreaker.value = true
})
</script>

<template>
  <div v-if="isDefined(currentState)">
    <template v-if="currentState.id === STATES_UI_PAYMENT.PREPARATION">
      <AppLoading>
        <p>
          {{ $t('commons.processing-paiement') }}
        </p>
      </AppLoading>
    </template>

    <template v-else-if="currentState.id === STATES_UI_PAYMENT.EXECUTION">
      <AppLoading>
        <p>
          {{ $t('commons.executing-paiement') }}
        </p>
      </AppLoading>
    </template>

    <template v-else-if="currentState.id === STATES_UI_PAYMENT.FINALIZATION">
      <AppLoading>
        <p>
          {{ $t('commons.finalizing-paiement') }}
        </p>
      </AppLoading>
    </template>

    <template v-else-if="currentState.id === STATES_UI_PAYMENT.SYNCHRONISATION">
      <AppLoading>
        <p>
          {{ $t('commons.confirming-trip') }}
        </p>
      </AppLoading>
    </template>

    <template v-else-if="currentState.id === STATES_UI_PAYMENT.MB_WAY_EXECUTION">
      <AppLoading>
        <p>
          {{ $t('commons.executing-paiement') }}
        </p>
      </AppLoading>
    </template>

    <template v-else-if="currentState.id === STATES_UI_PAYMENT.MB_WAY_FINALIZATION">
      <div class="space-y-8">
        <div class="flex flex-col gap-y-1 p-4 bg-peacock-50 rounded">
          <p class="font-bold text-peacock-500">
            <YscIconsCheckCircle class="h-5 w-5 inline mr-2 align-sub" />{{ $t('pages.checkout.async.created.description_dynamic', { phone_string: phone }) }}
          </p>
          <p class="text-sm text-peacock-700">
            {{ $t('pages.checkout.async.created.waiting_for_validation') }}
          </p>
        </div>

        <div
          v-if="shouldDisplayMbWayRetry"
          class="rounded border-4 border-peacock-50 p-4 space-y-4"
        >
          <h3 class="font-bold text-peacock-500">
            {{ $t('pages.checkout.async.waiting.no_notification') }}
          </h3>
          <ul class="text-sm list-inside list-disc">
            <li>
              {{ $t('pages.checkout.async.waiting.suggest_edit_phone') }}
            </li>
            <li>
              {{ $t('pages.checkout.async.waiting.suggest_edit_payment') }}
            </li>
          </ul>
        </div>

        <div class="flex justify-center">
          <YscLoader class="w-10 h-10" />
        </div>

        <div
          v-if="shouldDisplayMbWayRetry"
          class="flex justify-between"
        >
          <button
            class="btn btn-primary"
            @click="$emit('abort')"
          >
            {{ $t('pages.checkout.async.waiting.edit_phone') }}
          </button>
          <button
            class="link link-secondary cursor-pointer"
            @click="$emit('abort')"
          >
            {{ $t('pages.checkout.async.waiting.edit_payment') }}
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
